import request from '@/utils/request'

export function createOrderEnquiryTicket (data) {
  return request({
    url: '/ticket/create-order-enquiry',
    method: 'post',
    data: data
  })
}

export function createParcelEnquiryTicket (data) {
  return request({
    url: '/ticket/create-parcel-enquiry',
    method: 'post',
    data: data
  })
}

export function createOtherTicket (data) {
  return request({
    url: '/ticket/create-other-ticket',
    method: 'post',
    data: data
  })
}

export function updateTicket (data){
  return request({
    url: '/ticket/update',
    method: 'put',
    data: data
  })
}

export function deleteTicket (id){
  return request({
    url: '/ticket/delete?id=' + id,
    method: 'delete'
  })
}

export function closeTicket(id){
  return request({
    url: '/ticket/close?id='+id,
    method: 'put'
  })
}

export function reOpenTicket(id){
  return request({
    url: '/ticket/reopen?id='+id,
    method: 'put'
  })
}

export function listTicketReplies (ticketId){
  return request({
    url: '/ticket/reply/list?ticketId=' + ticketId,
    method: 'get',
  })
}

export function createReply (data){
  return request({
    url: '/ticket/reply/create',
    method: 'post',
    data: data
  })
}

export function readTicket (id){
  return request({
    url: '/ticket/read?id=' + id,
    method: 'get'
  })
}

export function listTickets (query){
  return request({
    url: '/ticket/list',
    method: 'get',
    params: query
  })
}

export function deleteReply(id){
  return request({
    url: '/ticket/reply/delete?id=' + id,
    method: 'delete'
  })
}

export function getUnRepliedTicketCount(){
  return request({
    url: '/ticket/un-replied-count',
    method: 'get'
  })
}

export function updateReply(data){
  return request({
    url: '/ticket/reply/update',
    method: 'put',
    data: data
  })
}

export function transferTicket(data){
  return request({
    url: '/ticket/transfer',
    method: 'put',
    data: data
  })
}

export function listPaymentLogsByUserId(query){
  return request({
    url: '/ticket/list-payment-logs-by-user-id',
    method: 'get',
    params: query
  })
}

export function getDefaultTicketReceiversConfig(){
  return request({
    url: '/ticket/default-ticket-receivers-config',
    method: 'get'
  })
}

export function updateTicketReceiversConfig(data){
  return request({
    url: '/ticket/update-ticket-receivers-config',
    method: 'put',
    data: data
  })
}

export function getPaymentLog(query){
  return request({
    url: '/ticket/get-payment-log',
    method: 'get',
    params: query
  })
}

export function withdraw(data){
  return request({
    url: '/ticket/withdraw',
    method: 'put',
    data: data
  })
}

export function recharge(data){
  return request({
    url: '/ticket/recharge',
    method: 'put',
    data: data
  })
}
export function listWithdrawalLogs(query){
  return request({
    url: '/ticket/list-withdrawal-logs',
    method: 'get',
    params: query
  })
}

export function  getUserBalanceInfo(query){
  return request({
    url: '/ticket/get-user-balance-info',
    method: 'get',
    params: query
  })
}

export function convertFromCNY(query){
  return request({
    url: '/ticket/convert-from-cny',
    method: 'get',
    params: query
  })
}

export function getAIAnswer(ticketId){
  return request({
    url: '/ticket/get-ai-answer?ticketId='+ticketId,
    method: 'get'
  })
}

export function evaluateAIAnswer(data){
  return request({
    url: '/ticket/evaluate-answer',
    method: 'put',
    data: data
  })
}

export function genAIAnswerForNewTicket(data){
  return request({
    url: '/ticket/gen-ai-answer-for-new-ticket',
    method: 'put',
    params: data
  })
}

export function updateTicketTag(data){
  return request({
    url: '/ticket/update-ticket-tag',
    method: 'post',
    params: data
  })
}

export function fixPaymentLogs(data){
  return request({
    url: '/ticket/fix-payment-logs',
    method: 'put',
    params: data
  })
}

export function deduct(data){
  return request({
    url: '/ticket/deduct',
    method: 'put',
    data: data
  })
}

export function putIntoTicketPool(data){
  return request({
    url: '/ticket/to-ticket-pool',
    method: 'put',
    params: data
  })
}
